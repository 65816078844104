<!-- @format -->

<template>
  <div>
    <MobileHeader />

    <MobileTop :kinostart="kinostart" />

    <MobileTrailerSizzle />

    <!-- Inhalt -->
    <div id="mob-inhalt">
      <MobileExpandableCard
        class="mob-inhalt"
        image="card_inhalt.jpg"
        btnTopAlign="left"
        btnTopColor="white"
        btnTopBg="transparent"
        btnBottomColor="white"
        btnBottomBg="#d42c2c"
        cardBorderRadius="0px"
        imageAlign="center"
        cardBg="#d42c2c"
        headlineFontsize="45px"
        teaserColor="black"
      >
        <!-- H1 Headline -->
        <template v-slot:headline>
          <div v-html="$texte.inhalt.h1"></div>
        </template>
        <!-- Teaser -->
        <template v-slot:teaser>
          <div v-html="$texte.inhalt.teaser"></div>
        </template>
        <!-- Hidden Maincontent -->
        <div v-html="$texte.inhalt.text"></div>
      </MobileExpandableCard>
    </div>

    <!-- Fotos -->
    <div id="mob-fotos" @click="foto = 0">
      <MobileCard
        class="mob-fotos"
        image="card_fotos.jpg"
        btnTopAlign="left"
        btnTopColor="white"
        btnTopBg="transparent"
        btnBottomColor="white"
        btnBottomBg="#d42c2c"
        cardBorderRadius="0px"
        openButton="Zur Bildergalerie"
        cardBg="#d42c2c"
        headlineFontsize="45px"
        teaserColor="black"
      >
        <!-- H1 Headline -->
        <template v-slot:headline>
          Fotos
        </template>
        <!-- Teaser -->
        <template v-slot:teaser>
          <b
            >Einen Auszug von Fotografien des in Berlin geborenen
            Ausnahmekünstlers Helmut Newton gibt es hier ...</b
          >
        </template>
        <!-- Hidden Maincontent -->
        <div v-html="$texte.inhalt.text"></div>
      </MobileCard>
    </div>

    <!-- Interview -->
    <div id="mob-interview">
      <MobileExpandableCard
        class="mob-interview"
        image="card_interview.jpg"
        btnTopAlign="left"
        btnTopColor="white"
        btnTopBg="transparent"
        btnBottomColor="white"
        btnBottomBg="#d42c2c"
        cardBorderRadius="0px"
        cardBg="#d42c2c"
        headlineFontsize="45px"
        teaserColor="black"
      >
        <!-- H1 Headline -->
        <template v-slot:headline>INTERVIEW</template>
        <!-- Teaser -->
        <template v-slot:teaser>
          <div v-html="$texte.interview.teaser"></div>
        </template>
        <!-- Hidden Maincontent -->
        <div v-html="$texte.interview.more"></div>
      </MobileExpandableCard>
    </div>

    <BaseFooter :kinostart="kinostart" :billing="true" :youtubenote="false" />
    <BaseModalGallery :foto="foto" />
  </div>
</template>

<script>
  import moment from 'moment'

  import texte from '@/views/texte/all.json'

  export default {
    components: {},
    data() {
      return {
        foto: null,
        images: [],
        texte: texte,
      }
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
      ratio() {
        return (
          this.$store.getters['Window/isSize'].width /
          this.$store.getters['Window/isSize'].height
        )
      },
      trailer() {
        return this.$config.youtube.id !== ''
      },
    },
    mounted() {
      for (let index = 0; index < this.$config.gallery.count; index++) {
        this.images[index] = require(`@/assets/images/gallery/` +
          this.pad(index + 1, 1) +
          `.jpg`)
      }
    },
    methods: {
      pad(num, size) {
        let s = num + ''
        while (s.length < size) s = '0' + s
        return s
      },
    },
  }
</script>

<style lang="scss">
  body {
    background-color: $primary;
  }
  #mob-interview {
    margin-bottom: 50px;
  }
</style>
